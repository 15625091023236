/**
* First we will load all of this project's JavaScript dependencies which
* includes Vue and other libraries. It is a great starting point when
* building robust, powerful web applications using Vue and Laravel.
*/

require('./bootstrap');

// import swal from 'sweetalert';
import swal2 from 'sweetalert2';
import bsCustomFileInput from 'bs-custom-file-input/dist/bs-custom-file-input.min.js';
import fontawesome from '@fortawesome/fontawesome-free/js/all.min.js';
import AOS from 'aos';

// window.Vue = require('vue');
window.swal = require('sweetalert');
window.swal2 = require('sweetalert2');

jQuery(function($){
	bsCustomFileInput.init();
	AOS.init();
})


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('selected-for-price-component', require('./components/SelectedForPriceComponent.vue').default);
// Vue.component('employee-fornt-index', require('./components/EmployeeFrontIndex.vue').default);
// Vue.component('front-employees-search', require('./components/FrontEmployeesSearch.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

 // const app = new Vue({
 // 	el: '#app',
 // });
